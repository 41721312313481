<template>
    <div style="width: 100%; background-color: #f7f5f9; overflow: auto">
        <a-modal
            title="Title"
            :visible="duplicate.show"
            :confirm-loading="duplicate.load"
            @ok="handleDuplicate"
            @cancel="closeDuplicate"
        >
            <a-form>
                <a-form-item label="Name your template">
                    <a-input v-model="duplicate.name" />
                </a-form-item>
            </a-form>
        </a-modal>

        <TemplateDrawer @next="emailBuilt" />

        <div class="dF jSB">
            <h4 style="margin-left: 15px" class="mb-3 mt-3">{{ emailType }}</h4>
        </div>

        <div
            class="contacts-page container"
            :class="[
                emailType === 'Choose a starting point' ? 'contactspage2' : '',
            ]"
            style="
                background-color: white;
                padding-right: 0;
                padding-left: 0;
                margin-bottom: 15px;
                margin-right: 15px;
                margin-left: 15px;
            "
        >
            <a-steps
                v-if="current === 1"
                :current="current"
                class="mb-5"
                style="width: 70%; padding-top: 25px; padding-left: 25px"
            >
                <a-step
                    v-for="item in steps"
                    :key="item.title"
                    :title="item.title"
                >
                    <span slot="description">{{ item.description }}</span>
                </a-step>
            </a-steps>

            <a-card v-if="current === 0">
                <a-steps
                    :current="current"
                    class="mb-5"
                    style="width: 100%; padding-right: 25px"
                >
                    <a-step
                        v-for="item in steps"
                        :key="item.title"
                        :title="item.title"
                    >
                        <span slot="description">{{ item.description }}</span>
                    </a-step>
                </a-steps>

                <a-form-model ref="ruleForm" :model="broadcast" :rules="rules">
                    <a-form-model-item
                        ref="name"
                        label="Name this template"
                        prop="name"
                    >
                        <a-input
                            v-model="broadcast.name"
                            placeholder="Add template name"
                            size="large"
                            style="width: 70%"
                        />
                    </a-form-model-item>
                </a-form-model>

                <div class="mt-5" style="width: 100%; text-align: right">
                    <a-button
                        @click="cancelBroadcast"
                        class="mr-3"
                        style="width: 100px; height: 35px"
                        >CANCEL</a-button
                    >
                    <a-button
                        @click="nextStep"
                        type="primary"
                        style="width: 100px; height: 35px"
                        >NEXT</a-button
                    >
                </div>
            </a-card>

            <a-card v-if="current === 2">
                <a-steps
                    :current="current"
                    class="mb-5"
                    style="width: 100%; padding-right: 25px"
                >
                    <a-step
                        v-for="item in steps"
                        :key="item.title"
                        :title="item.title"
                    >
                        <span slot="description">{{ item.description }}</span>
                    </a-step>
                </a-steps>

                <h5 class="mb-5">Who are you sending this Broadcast to?</h5>

                <a-form-model
                    ref="step3form"
                    :model="broadcast"
                    :rules="step3Rules"
                >
                    <a-form-model-item
                        ref="inc"
                        label="Send to All Contacts"
                        prop="allContacts"
                    >
                        <a-switch v-model="broadcast.allContacts" />
                    </a-form-model-item>
                    <a-form-model-item
                        ref="inc"
                        :label="`Include Recipient by Tags`"
                        prop="tagI_any"
                    >
                        <a-select
                            :disabled="broadcast.allContacts"
                            v-model="broadcast.tagI_any"
                            mode="multiple"
                            style="width: 70%"
                            :options="tags"
                        />
                    </a-form-model-item>

                    <a-form-model-item
                        ref="exclude"
                        :label="`Exclude Recipient by Tags`"
                        prop="tagE_any"
                    >
                        <div
                            style="
                                width: 100%;
                                color: #9ea0a5;
                                line-height: 1;
                                margin-bottom: 15px;
                            "
                        >
                            Exclude anyone in this list from receiving this
                            broadcast
                        </div>
                        <a-select
                            :disabled="broadcast.allContacts"
                            v-model="broadcast.tagE_any"
                            mode="multiple"
                            style="width: 70%"
                            size="large"
                            :options="tags"
                        />
                    </a-form-model-item>
                </a-form-model>

                <div class="mt-5" style="width: 100%; text-align: right">
                    <a-button
                        @click="backToEditor"
                        class="mr-3"
                        style="width: 100px; height: 35px"
                        >BACK</a-button
                    >
                    <a-button
                        @click="lastStep"
                        type="primary"
                        style="width: 100px; height: 35px"
                        >NEXT</a-button
                    >
                </div>
            </a-card>

            <a-card v-if="current === 3">
                <a-steps
                    :current="current"
                    class="mb-5"
                    style="width: 100%; padding-right: 25px"
                >
                    <a-step
                        v-for="item in steps"
                        :key="item.title"
                        :title="item.title"
                    >
                        <span slot="description">{{ item.description }}</span>
                    </a-step>
                </a-steps>

				<a-form-model-item class="mt-3">
					<template slot="label">
						Use Send Time Optimization
						<a-tooltip placement="right" overlayClassName="change-tooltip-color">
							<template slot="title" style="width: 400px;">
								Improve your email conversion and open rate by automatically sending emails at the exact moment a recipient views their inbox with Send Time Optimization based on recipients previous engagements.
							</template>
							<a-icon type="question-circle"
								style="font-size: 14px; color: black; margin-left: 2px;" />
						</a-tooltip>
					</template>
	                <a-radio-group v-model="broadcast.useSendTimeOptimization">
	                    <a-radio-button :value="true">
	                        Yes
	                    </a-radio-button>
	                    <a-radio-button :value="false">
	                        No
	                    </a-radio-button>
	                </a-radio-group>
	            </a-form-model-item>

                <h5 class="mb-3">
                    When would you like to send this broadcast?
                </h5>

                <a-radio-group v-model="value" @change="onChange">
                    <a-radio
                        style="display: block; height: 30px; line-height: 30px"
                        value="now"
                    >
                        Send it now
                    </a-radio>
                    <a-radio
                        style="display: block; height: 30px; line-height: 30px"
                        value="later"
                        disabled
                    >
                        Schedule a specific time and date<br />
                        <a-date-picker
                            v-if="value === 'later'"
                            class="mt-3"
                            show-time
                            placeholder="Select Date/Time"
                            @change="onChange2"
                            @ok="onOk"
                        />
                    </a-radio>
                </a-radio-group>

                <div class="mt-5" style="width: 100%; text-align: right">
                    <a-button
                        @click="current = 2"
                        class="mr-3"
                        style="width: 100px; height: 35px"
                        >BACK</a-button
                    >
                    <a-button
                        @click="submit"
                        type="primary"
                        style="width: 100px; height: 35px"
                        >FINISH</a-button
                    >
                </div>
            </a-card>
        </div>
    </div>
</template>

<script>
import { arrToObj, formatDate } from "bh-mod";
import TemplateDrawer from "@/components/email/TemplateDrawer";
import moment from "moment";
import axios from "axios";

export default {
    components: {
        TemplateDrawer,
    },
    data() {
        const validateIncludeTags = (rule, value, callback) => {
            if (this.broadcast.allContacts) return callback();
            if (this.broadcast.tagE_any && this.broadcast.tagE_any.length)
                return callback();
            if (!value.length) {
                callback(
                    new Error(
                        "You must choose at least one Tag to include/exclude"
                    )
                );
            } else {
                callback();
            }
        };
        const validateExclude = (rule, value, callback) => {
            if (this.broadcast.allContacts) return callback();
            if (this.broadcast.tagI_any && this.broadcast.tagI_any.length)
                return callback();
            if (!value.length) {
                callback(
                    new Error(
                        "You must choose at least one Tag to include/exclude"
                    )
                );
            } else {
                callback();
            }
        };
        const validateAllContacts = (rule, value, callback) => {
            this.$refs.step3form &&
                this.$refs.step3form.validateField("tagI_any");
            callback();
        };
        return {
            tags: [],
            duplicate: {
                name: "",
                id: "",
                show: false,
                load: false,
            },
            timeNow: Date.now(),
            mailOpen: "",
            filter: "all",
            baseImage: "",
            loadModal: false,
            taskOpen: "",
            appointmentOpen: "",
            searchMode: false,
            activeTab: "1",
            testEmail: [],
            value: "now",
            broadcast: {
                id: "",
                name: "",
                subject: "",
                preheader: "",
                recipient: [],
                exclude: [],
                scheduled: "",
            },
            rules: {
                name: [
                    {
                        required: true,
                        message: "Please input a template name",
                        trigger: "blur",
                    },
                ],
            },
            step3Rules: {
                allContacts: [
                    { validator: validateAllContacts, trigger: "change" },
                ],
                tagI_any: [
                    { validator: validateIncludeTags, trigger: "change" },
                ],
                tagE_any: [{ validator: validateExclude, trigger: "change" }],
            },

            selectedRowKeys: [],
            bulkActions: [
                {
                    label: "Delete Selected Forms/Surveys",
                    value: "delete",
                },
            ],

            current: 0,
            steps: [
                {
                    title: "Step 1",
                    description: "Define Template Info",
                },
                {
                    title: "Step 2",
                    description: "Content Design",
                },
            ],
        };
    },
    watch: {
        currentStep() {
            this.current = 2;
        },
    },
    computed: {
        currentStep() {
            return this.$store.state.step;
        },
        sender() {
            let senders = this.$store.state.email.senders;
            for (var i in senders) {
                return senders[i];
            }
        },
        emailType() {
            return this.$store.state.emailType;
        },
        broadcasts() {
            return this.$store.state.email.broadcasts;
        },
        templates() {
            let newArray = [];
            let templates = Object.values(this.$store.state.email.templates);

            let blank = templates.find((x) => x.name.includes("Blank T"));
            if (blank)
                newArray.push(blank),
                    (templates = templates.filter(
                        (x) => !x.name.includes("Blank T")
                    ));
            newArray = [...newArray, ...templates];
            // return newArray
            return arrToObj(newArray);
        },
        drafts() {
            return this.$store.state.email.drafts;
        },
        siteURL() {
            return this.$store.state.email.siteURL;
        },
        searchOBJ() {
            return this.$store.state.email.searchOBJ;
        },
        resultLength() {
            return this.$store.state.email.resultLength;
        },
        showingContactMode() {
            return this.listMode === "all"
                ? `Showing All Contacts (${this.resultLength} Contacts) `
                : `Found ${this.resultLength} Contacts `;
        },
        newStoreTime() {
            return this.$store.state.email.newStoreTime;
        },
        instance() {
            return this.$store.state.instance;
        },
        pageSize() {
            return this.$store.state.email.pageSize;
        },
        query() {
            return this.$store.getters.query;
        },
        dialog() {
            return this.$store.state.email.broadcastModal;
        },
        loadingPage: {
            get() {
                return this.$store.state.email.loadingPage;
            },
            set(val) {
                this.$store.commit("LOAD_PAGE", val);
            },
        },
    },
    methods: {
        emailBuilt(email) {
            this.email = email;
        },
        backToEditor() {
            this.$store.commit("SHOW_EDIT_TEMPLATE", this.email);
        },
        moment,
        async submit() {
            let sendOBJ = this.email;
            sendOBJ.SUBJECT = this.broadcast.subject;
            sendOBJ.PREVIEW = this.broadcast.preheader;

            this.$store.commit("LOAD");

            let { data: html } = await axios.post(
                `${this.siteURL}/email?final=1`,
                sendOBJ
            );

            if (typeof html !== "string")
                return (
                    this.$store.commit("LOAD", false),
                    this.$message.error("There was a problem creating HTML")
                );

            sendOBJ = JSON.parse(JSON.stringify(this.broadcast));
            sendOBJ.from = this.sender.id;
            sendOBJ.project = this.email.id;
            sendOBJ.prodHtml = html;
            sendOBJ.scheduled = this.sendNow
                ? 0
                : new Date(sendOBJ.scheduled).getTime();

            this.$api
                .post(`/broadcasts/:instance`, sendOBJ)
                .then(({ data }) => {
                    this.$store.commit("LOAD", false);
                    this.$store.commit("SET_PROP", {
                        where: ["broadcasts", data.id],
                        what: data,
                    });
                    this.$router.push("/");
                })
                .catch((err) => {
                    this.$store.commit("LOAD", false);
                    this.$message.error(
                        "There was an error while dispatching your email"
                    );
                });
        },
        lastStep() {
            this.$refs.step3form.validate((valid) => {
                if (valid) {
                    this.current = 3;
                } else {
                    console.error("error!!");
                    return false;
                }
            });
        },
        sendTest(testEmail) {
            let url = `/services/email/send`;

            if (this.testEmail != []) {
                this.testEmail = [];
            }

            this.testEmail = testEmail;
            let html = this.packageEmail();
            this.$api.post(url, {
                to: this.testEmail.join(","),
                subject: this.broadcast.subject,
                from: "promotion@test.bildhive.net",
                html,
            }).catch(err => {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err))
				}
			});
            this.$store.commit("CLOSE_BROADCAST");
        },
        packageEmail() {
            let fontGen = (font) => {
                return `<link data-font href="https://fonts.googleapis.com/css?family=${font.name
                    .split(" ")
                    .join("+")}:${font.weights.join(
                    ","
                )}" rel="stylesheet" type="text/css">`;
            };
            let fontGenStyle = (font) => {
                return `@import url(https://fonts.googleapis.com/css?family=${font.name
                    .split(" ")
                    .join("+")}:${font.weights.join(",")});`;
            };

            if (!this.dialog.data.html)
                return this.$message.error("No HTML found");
            if (!this.broadcast.subject.trim())
                return this.$message.error("Please fill out Subject");

            let emailHTML = this.dialog.data.html;
            let html = document.createElement("html");
            html.innerHTML = emailHTML;

            let parentNode = html.querySelector("body");
            let refChild = html.querySelector("body").children[0];
            let previewDOM = document.createElement("div");

            previewDOM.innerHTML = `<div style="display:none;font-size:1px;color:#ffffff;line-height:1px;max-height:0px;max-width:0px;opacity:0;overflow:hidden;">
                                            ${this.broadcast.preheader}
                                        </div>`;

            parentNode.insertBefore(previewDOM, refChild);
            html = html.innerHTML;
            html =
                `<!DOCTYPE html>↵<html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">` +
                html +
                `</html>`;
            html = html.replace(/\n/gi, "");
            html = html.replace("↵", "");
            html = html.replace("https://mjml.io", "");
            html = html.replace("[[SUBJECT]]", this.broadcast.subject);
            let fields = [
                { search: /%%COMMUNITYNAME%/gi, replace: this.instance.name },
                {
                    search: /%%CONTACT_INFO%/gi,
                    replace: `${this.instance.address}, ${this.instance.city} ${this.instance.region}`,
                },
                { search: /%%name/gi, replace: "%recipient_name" },
                { search: /%%fname%/gi, replace: "%recipient.firstName%" },
                { search: /%%lname%/gi, replace: "%recipient.lastName%" },
                { search: /%%email%/gi, replace: "%recipient_email%" },
                { search: /%%5/gi, replace: "%recipient.5" },
            ];

            let fonts =
                this.dialog.data.options && this.dialog.data.options.fonts
                    ? this.dialog.data.options.fonts
                    : false;

            if (fonts) {
                let fontHTML = fonts.map((x) => fontGen(x)).join(" ");
                fontHTML =
                    fontHTML +
                    `<style data-font>${fonts
                        .map((x) => fontGenStyle(x))
                        .join(" ")}</style>`;
                html = html.replace(
                    "<style",
                    `<!--[if mso]>
                                        <xml>
                                        <o:OfficeDocumentSettings>
                                        <o:AllowPNG/>
                                        <o:PixelsPerInch>96</o:PixelsPerInch>
                                        </o:OfficeDocumentSettings>
                                        </xml>
                                        <![endif]-->
                                    <!--[if lte mso 11]>
                                        <style type="text/css">
                                        .outlook-group-fix { width:100% !important; }
                                        </style>
                                        <![endif]--><!--[if !mso]><!-->${fontHTML}<!--<![endif]--> <style`
                );
            }

            fields.forEach(({ search, replace }) => {
                html = html.replace(search, replace);
            });

            return html;
        },
        nextStep() {
            if (this.current == 0) {
                this.$refs.ruleForm.validate((valid) => {
                    if (valid) {
                        // this.$store.commit('LOAD')
                        //run PUT here before showing edit template
                        let sendObj = JSON.parse(
                            JSON.stringify(this.broadcast)
                        );
                        this.$api
                            .get(`/projects/:instance/${sendObj.project}`)
                            .then(({ data: email }) => {
                                email.page = email.pages[0];
                                delete email.pages;
                                this.email = email;
                                email.name = this.broadcast.name;
                                email.SUBJECT = this.broadcast.subject;
                                email.PREVIEW = this.broadcast.preheader;
                                this.$store.commit("SHOW_EDIT_TEMPLATE", email);
                            }).catch(err => {
								if (!err || !err.response || !err.response.status || err.response.status !== 400) {
									this.$message.error(this.$err(err))
								}
							});
                    } else {
                        console.error("error!!");
                        return false;
                    }
                });
            }
        },
        cancelBroadcast() {
            this.$router.push(`/`);
            this.$store.commit("EMAIL_TYPE", { value: "Drafts" });
        },
        async bulkActioned({ key, keyPath, val }) {
            let self = this;

            if (key == "delete") {
                this.$confirm({
                    title: "Delete",
                    content: (h) => (
                        <div>Do you want to delete these forms/surveys?</div>
                    ),
                    okText: "Delete",
                    okType: "danger",
                    cancelText: "Cancel",
                    centered: true,
                    onOk() {
                        for (var i = 0; i < self.selectedRowKeys.length; i++) {
                            let id = self.selectedRowKeys[i];
                        }
                        setTimeout(() => {
                            self.selectedRowKeys = [];
                        }, 1000);
                    },
                });
            }
        },
        formatDate,
        getDeliveredPerc({ counts, contacts }) {
            let { delivered = 0 } = counts;
            return (delivered / contacts.length) * 100;
        },
        itemMenuClick({ item, key, keyPath }, broadcast) {
            if (key === "dup") {
                this.dupTemplate(broadcast.email);
            } else if (key === "report") {
                this.$message.error(
                    "This functionality is currently not available"
                );
            }
        },
        async handleDuplicate({ id, name }) {
            this.broadcast.id = id;

            this.duplicate.load = true;

            let sendObj = JSON.parse(JSON.stringify(this.broadcast));
            let { data } = await this.$api.post(
                `/emails/${this.instance.id}/clone`,
                sendObj
            ).catch(err => {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err))
				}
			});

            this.$store.commit("SHOW_EDIT_TEMPLATE", data);

            setTimeout(() => {
                this.duplicate.load = false;
                this.closeDuplicate();
                this.$store.commit("EMAIL_TYPE", { value: "Drafts" });
            }, 1000);
        },
        dupTemplate({ id, name }) {
            this.duplicate = {
                show: true,
                id,
                name,
            };
        },
        closeDuplicate() {
            this.duplicate = {
                show: false,
                name: "",
                id: "",
            };
        },
        openTemplate(template) {
            this.$store.commit("SHOW_EDIT_TEMPLATE", template);
        },
        removeSearch({ srch, searchKey }) {
            this.$store.commit("REMOVE_SEARCH_PARAM", { srch, searchKey });
        },
        onChange(e) {
            if (e.target.value == "now") {
                this.broadcast.scheduled = "";
            }
        },
        onChange2(value, dateString) {
            this.broadcast.scheduled = this.moment(dateString).format("X");
        },
        onOk(value) {
            console.log("onOk: ", value);
        },
    },
    mounted() {
        if (location.search.includes("edit")) {
            this.nextStep();
        }
    },
    created() {
        this.$store.commit("EMAIL_TYPE", { value: "Edit Template" });

        let id = this.$route.params.id;
        let templates = Object.values(this.$store.state.email.templates);

        let found = templates.find((item) => {
            return item.id === id;
        });

        let draft = {
            name: found?.name,
            subject: "New Release this weekend. Come in and Take it!",
            previewText: "Preheader goes here....",
            allContacts: true,
            tagI_any: [],
            tagE_any: [],
            scheduled: 0,
            id: found?.id + "temp",
            project: found?.id,
            instance: this.$store.state.instance.id,
        };

        this.broadcast = JSON.parse(JSON.stringify(draft));
    },
};
</script>



<style lang="scss" module>
@import "./style.module.scss";
</style>



<style lang="scss" scoped>
.contactspage2 {
    @media screen and (min-width: 567px) {
        max-width: calc(100% - 35px) !important;
    }
}
.contacts-page {
    @media screen and (min-width: 567px) {
        max-width: 70%;
    }
}
.aB {
    align-items: baseline;
}

.row-mb {
    [class^="ant-col"] {
        margin-bottom: 20px;
    }
}
</style>
